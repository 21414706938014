import { makeVar } from '@apollo/client';
import type { User } from 'auth/types';

const isUserInLocalStorage = () => {
    const user = localStorage.getItem('user');
    if (user) {
        try {
            JSON.parse(user);
            return true;
        } catch {
            return false;
        }
    } else {
        return false;
    }
}

const getUserFromLocalStorage = (): User | null => {
    const user = localStorage.getItem('user');
    if (user) {
        try {
            return JSON.parse(user);
        } catch {
            return null;
        }
    } else {
        return null;
    }
}

export const isAuthenticatedVar = makeVar(isUserInLocalStorage());
export const userDataVar = makeVar(getUserFromLocalStorage());