import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledFooter = styled.footer`
    background-color: lightgrey;
`;

const Footer = () => {
    return (
        <StyledFooter className="py-4 mt-4">
            <Container>
                <Row>
                    <Col>
                        <h5>Memory Inbox</h5>
                        <ul className="list-unstyled">
                            <li>Features</li>
                            <li>Pricing</li>
                        </ul>
                    </Col>
                    <Col>
                        <h5>Social Media</h5>
                        <ul className="list-unstyled">
                            <li>Facebook</li>
                            <li>Twitter</li>
                        </ul>
                    </Col>
                    <Col>
                        <h5>Memory Inbox</h5>
                        <ul className="list-unstyled">
                            <li>Team</li>
                            <li>History</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </StyledFooter>
    )
};

export default Footer;