import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { ApolloLink, ApolloClient, ApolloProvider, HttpLink, InMemoryCache, TypePolicies } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import authInit from './auth';
import { buildGraphQLTypePolicies } from './graphql';
import { signOut } from './auth/operations';

let client: ApolloClient<any>;

const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, extensions }) => {
            if (extensions) {
                // For graphql auth errors
                if (extensions.code === "UNAUTHENTICATED") {
                    signOut(client);
                    return null;
                }
            }
            return console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
        });
    }
    if (networkError) {
        // For /auth endpoints
        if ('statusCode' in networkError && networkError.statusCode === 401) {
            signOut(client);
        } else {
            console.error(`[Network error]: ${networkError}`);
        }
    }
});

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}/api`,
    credentials: 'include'
});

// Declared earlier
client = new ApolloClient({
    cache: new InMemoryCache({
        // TODO: Remove kludge
        typePolicies: buildGraphQLTypePolicies() as TypePolicies
    }),
    link: ApolloLink.from([
        errorLink,
        httpLink
    ])
});

authInit(client);

const rootElement = document.getElementById('root');
if (!rootElement) {
    throw new Error("Somehow we're missing the root div?");
}

render(
    <ApolloProvider client={client}>
        <SnackbarProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router>
                    <App />
                </Router>
            </MuiPickersUtilsProvider>
        </SnackbarProvider>
    </ApolloProvider>
    ,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
