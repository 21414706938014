import { gql } from "@apollo/client";

export const GET_AUTHENTICATION_STATE = gql`
{
    isAuthenticated @client
}
`;

export const GET_USER_DATA = gql`
{
    userData @client {
        id
        username
        name
        email
    }
}
`;

// A combination of the previous two to avoid race conditions
export const GET_AUTHENTICATION_ALL = gql`
{
    isAuthenticated @client
    userData @client {
        id
        username
        name
        email
    }
}
`;
