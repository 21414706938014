import { isAuthenticatedVar, userDataVar } from './variables';
import { addTypePolicy } from '../helpers';
import type { TypePolicies } from '@apollo/client';

export const registerAuthTypePolicies = (policy: TypePolicies) => {
    addTypePolicy(policy, {
        Query: {
            fields: {
                isAuthenticated: {
                    read() {
                        return isAuthenticatedVar();
                    }
                },
                userData: {
                    read() {
                        return userDataVar();
                    }
                }
            }
        }
    });
}

export const initializeAuthState = () => {
    isAuthenticatedVar(false);
    userDataVar(null);
}