import { isPageLoadingVar } from './variables';
import { addTypePolicy } from '../helpers';
import type { TypePolicies } from '@apollo/client';

export const registerLoadingTypePolicies = (policy: TypePolicies) => {
    addTypePolicy(policy, {
        Query: {
            fields: {
                isPageLoading: {
                    read() {
                        return isPageLoadingVar();
                    }
                }
            }
        }
    });
}

export const initializeLoadingState = () => {
    isPageLoadingVar(false);
}