import React from 'react';
import { Dropdown, Navbar, Nav } from 'react-bootstrap';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_AUTHENTICATION_ALL } from 'graphql/auth/queries';
import styled from 'styled-components';
import { AuthenticationAll } from 'auth/types';

const StyledLink = styled(Nav.Link)`
    padding-right: 0;
`;

const Header = () => {
    const { data } = useQuery<AuthenticationAll>(GET_AUTHENTICATION_ALL);
    const { url } = useRouteMatch();

    return (
        <div>
            <Navbar className="pt-4 d-sm-none" expand="sm" bg="primary" variant="dark">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Link as={NavLink} to={`${url}/dashboard`}>Dashboard</Nav.Link>
                        <Nav.Link as={NavLink} to={`${url}/files`}>Files</Nav.Link>
                        <Nav.Link as={NavLink} to={`${url}/events`}>Events</Nav.Link>
                        <Nav.Link as={NavLink} to={`${url}/contacts`}>Contacts</Nav.Link>
                        <Nav.Link as={NavLink} to={`${url}/groups`}>Groups</Nav.Link>
                        <Nav.Link as={NavLink} to="/auth/logout">Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Navbar className="pt-4 d-none d-sm-flex">
                <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-primary my-2 my-0" type="submit">Search</button>
                </form>
                <Dropdown alignRight className="ml-auto">
                    <Dropdown.Toggle as={StyledLink} id="dropdown-basic" className="text-primary text-right">
                        Welcome, <b>{data && data.isAuthenticated ? data.userData.name : 'Guest'}</b>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/auth/logout">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar>
        </div>
    )
};

export default Header;
