import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled.div`
    height: 100%;
`;

const StyledNavLink = styled(NavLink)`
    :hover {
        text-decoration: none;
        color: white;
    }
    &.active {
        color: white;
    }

    color: #d4d4d4;

    padding-left: 0%;
`;

const WrappedNavLink = ((props: NavLinkProps) => (
    <h5>
        <StyledNavLink {...props}>
            {props.children}
        </StyledNavLink>
    </h5>
));

const Sidebar = () => {
    const { url } = useRouteMatch();

    return (
        <StyledContainer className="p-4 bg-primary">
            <h3 className="mb-5 text-white">Memory Inbox</h3>
            <Nav className="flex-column">
                <Nav.Link as={WrappedNavLink} to={`${url}/dashboard`}>Dashboard</Nav.Link>
                <Nav.Link as={WrappedNavLink} to={`${url}/files`}>Files</Nav.Link>
                <Nav.Link as={WrappedNavLink} to={`${url}/events`}>Events</Nav.Link>
                <Nav.Link as={WrappedNavLink} to={`${url}/contacts`}>Contacts</Nav.Link>
                <Nav.Link as={WrappedNavLink} to={`${url}/groups`}>Groups</Nav.Link>
            </Nav>
        </StyledContainer>
    )
};

export default Sidebar; 