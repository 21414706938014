import React, { useMemo, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { GET_FILE_UPLOADS } from 'graphql/FileUpload/queries';
import { clearFiles } from './operations';
import { useQuery } from '@apollo/client';
import File from './File';
import { FileUploadState } from './types';
import type { FileUploadsData } from './types';

const StyledFileUploadContainer = styled.div`
    position: fixed;
    bottom: 1vh;
    right: 1vh;
    min-width: 20%;
    z-index: 2000;
`;

const FileUpload = () => {
    const { data } = useQuery<FileUploadsData>(GET_FILE_UPLOADS);
    const files = useMemo(() => (data?.fileUploads || []), [data]);

    const shouldShow = files.length > 0;

    const isFileStateComplete = (state: FileUploadState) => {
        return state === FileUploadState.COMPLETE
            || state === FileUploadState.ERROR;
    }

    const shouldShowCloseButton = useMemo(() => {
        return files.every((file) => {
            return isFileStateComplete(file.state);
        });
    }, [files]);

    useEffect(() => {
        if (files.length === 0 || files.every((file) => {
            return isFileStateComplete(file.state);
        })) {
            window.onbeforeunload = null;
        } else {
            window.onbeforeunload = () => true;
        }
    }, [files]);

    const closeFileUpload = () => {
        clearFiles();
    }

    if (!shouldShow) {
        return null;
    }

    return (
        <StyledFileUploadContainer className="shadow">
            <Card>
                <Card.Header className="bg-dark text-light">
                    File Upload
                    {
                        shouldShowCloseButton &&
                        <button className="close" aria-label="Close" onClick={closeFileUpload}>
                            <span aria-hidden="true" className="text-light">&times;</span>
                        </button>
                    }
                </Card.Header>
                <Card.Body>
                    {
                        files.map((file, index) => (
                            <Row key={index} className="my-2">
                                <Col xs={12}>
                                    <File file={file} />
                                </Col>
                            </Row>
                        ))
                    }
                </Card.Body>
            </Card>
        </StyledFileUploadContainer>
    )
};

export default FileUpload;