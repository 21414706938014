import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Loading from 'Loading';
import NonPrivateRoute from 'NonPrivateRoute';

import AuthBackground from '../AuthBackground';
import AuthContainer from '../AuthContainer';
import Banner from '../AuthContainer/Banner';

const SignIn = lazy(() => import('../SignIn'));
const SignOut = lazy(() => import('../SignOut'));
const SignUp = lazy(() => import('../SignUp'));

const AuthRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <AuthBackground>
            <div className="pt-md-5">
                <Banner />
                <Suspense fallback={<Loading />}>
                    <AuthContainer>
                        <Switch>
                            <NonPrivateRoute path={`${path}/login`} exact component={SignIn} />
                            <Route path={`${path}/logout`} exact component={SignOut} />
                            <NonPrivateRoute path={`${path}/signup`} exact component={SignUp} />
                            <Redirect to="/404" />
                        </Switch>
                    </AuthContainer>
                </Suspense>
            </div>
        </AuthBackground>
    )
};

export default AuthRoutes;
