import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
    height: 100vh;
`;

const StyledHeader = styled.h1`
    margin-top: 96px;
`;

const NotFound = () => {
    return (
        <StyledContainer>
            <StyledHeader className="text-center">Page Not Found</StyledHeader>
        </StyledContainer>
    )
};

export default NotFound;
