import axios from 'axios';
import { ApolloClient } from '@apollo/client';
import { signIn, signOut } from '../auth/operations';

const init = (client: ApolloClient<any>) => {
    /*
     * Optimistically load user data, we'll replace it later if something in the
     * user data changes (e.g. username/email) or just wipe it if the cookie is
     * expired
     */
    return axios.get(`${process.env.REACT_APP_API_URL}/auth/current`, {
        withCredentials: true
    })
        .then((response) => {
            localStorage.setItem('user', JSON.stringify(response.data));
            signIn(response.data);
        })
        .catch(() => {
            signOut(client);
        });
};

export default init;
