import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from 'NotFound';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Home from '../Home';

const PublicRoutes = () => {
    return (
        <>
            <Header />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/404" exact component={NotFound} />
                <Redirect to="/404" />
            </Switch>
            <Footer />
        </>
    )
};

export default PublicRoutes;