import React, { useState, useEffect, useRef } from 'react';
import Link from 'common/Link';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

const StyledNavbar = styled(Navbar)`
    transition: 300ms ease;
    * {
        transition: 300ms ease;
    }
`;

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const navbar = useRef(null);

    const handleScroll = () => {
        // Arbitrary point, just make sure it doesn't overlap
        window.scrollY > 10
            ? setIsScrolled(true)
            : setIsScrolled(false)
    }

    useEffect(() => {
        /*
         * Call it once to properly initialize behavior in case we're coming
         * in from another route.
         */
        handleScroll();
        window.addEventListener("scroll", throttle(handleScroll, 20))
        return () => {
            window.removeEventListener("scroll", () => handleScroll)
        }
    }, []);

    return (
        <StyledNavbar fixed="top" ref={navbar} bg={isScrolled ? 'dark' : ''} variant={isScrolled ? 'dark' : ''}>
            <Container>
                <Navbar.Brand>
                    Memory Inbox
                </Navbar.Brand>
                <Nav className="mr-auto d-none d-md-flex">
                    <Nav.Link as={Link} to="/">
                        Home
                    </Nav.Link>
                    <Nav.Link as={Link} to="/about">
                        About
                    </Nav.Link>
                </Nav>
                <Button variant="outline-primary" as={Link} to="/auth/login">
                    Get Started
                </Button>
            </Container>
        </StyledNavbar>
    )
};

export default Header;