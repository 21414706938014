import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const StyledHeader = styled.h1`
    color: white;
`;

const AuthContainer = () => {
    return (
        <Container className="pt-5 pb-3 d-flex justify-content-center align-items-center">
            <StyledHeader>Memory Inbox</StyledHeader>
        </Container>
    )
};

export default AuthContainer;