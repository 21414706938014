import { gql } from "@apollo/client";

export const GET_FILE_UPLOADS = gql`
{
    fileUploads @client {
        progress
        state
        file
    }
}
`;