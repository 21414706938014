import React, { useEffect } from 'react';
import { isPageLoadingVar } from '../graphql/loading/variables';

const Loading = () => {
    useEffect(() => {
        // Ideally we would use useMutation here but it seems to not work here,
        // instead complaining about unable to destructure null
        isPageLoadingVar(true);
        return () => {
            isPageLoadingVar(false);
        }
    }, []);
    return <></>;
};

export default Loading;
