import React from 'react';
import Helmet from 'react-helmet';

interface AuthContainerProps {
    children: React.ReactNode
};

const AuthContainer = (props: AuthContainerProps) => {
    return (
        <div>
            <Helmet>
                <style>
                    {`
                    body {
                        background: linear-gradient(to right, #1d4350, #a43931)
                    }
                    `}
                </style>
            </Helmet>
            {props.children}
        </div>
    )
}

export default AuthContainer;