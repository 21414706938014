import { isAuthenticatedVar, userDataVar } from '../graphql/auth/variables';
import { ApolloClient } from '@apollo/client';
import { initializeGraphQLState } from '../graphql';
import type { User } from './types';

export const signIn = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
    isAuthenticatedVar(true);
    userDataVar(user);
};

export const signOut = (client: ApolloClient<any>) => {
    localStorage.removeItem('user');
    client.resetStore()
        .catch(() => {
            /*
             * Deliberately empty to "swallow" any errors. This is necessary
             * to prevent any errors from popping up when we sign out
             * because of a UNAUTHENTICATED response from the API.
             *
             * The reason is that from Apollo's point of view, a request is
             * still in flight when we reset the store which results in an
             * invariant violation, causing it to choke and throw an error. In
             * development mode, this breaks the entire app, while on production
             * we just get a console warning.
             *
             * Since we know that a request is inflight, we can just safely
             * eliminate the error by catching and doing nothing.
             *
             * Note that if we explicitly call signOut from a component, this
             * should NOT run because no requests should be inflight in that
             * scenario.
             */
        });
    // Reinitialize the state
    initializeGraphQLState();
};
