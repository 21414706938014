import React from 'react';

interface ContentProps {
    children: React.ReactNode
}

const Content = (props: ContentProps) => {

    return (
        <div className="p-4">
            {props.children}
        </div>
    )
};

export default Content; 