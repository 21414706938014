import React from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { FileUploadState } from './types';
import type { FileUpload } from './types';

const toKb = (size: number) => {
    return Math.floor(size / 1024);
}

const fileMessage = (file: FileUpload) => {
    switch (file.state) {
        case FileUploadState.READY:
            return 'Ready to upload';
        case FileUploadState.PREPARING:
            return 'Preparing to upload';
        case FileUploadState.UPLOADING:
            return `${file.progress}%`;
        case FileUploadState.COMPLETE:
            return 'Uploaded';
        case FileUploadState.ERROR:
            return 'Failed to upload';
        default:
            return 'Unknown state';
    }
}

const shouldAnimate = (file: FileUpload) => {
    return file.state === FileUploadState.UPLOADING;
}

const progressColor = (file: FileUpload) => {
    switch (file.state) {
        case FileUploadState.COMPLETE:
            return 'success';
        case FileUploadState.ERROR:
            return 'danger';
        default:
            return 'info';
    }
}

interface FileProps {
    file: FileUpload,
}

const File = ({ file }: FileProps) => {
    console.log(file.file.name, file);
    return (
        <div>
            <Row>
                <Col md={12}>
                    <Row noGutters>
                        <span className="mr-auto font-weight-bold">{file.file.name}</span>
                    </Row>
                    <Row noGutters className="d-flex">
                        <span className="mr-auto font-weight-light">{`${toKb(file.file.size)} kB`}</span>
                        <span className="font-weight-light">{fileMessage(file)}</span>
                    </Row>
                    <Row noGutters>
                        <Col xs={12}>
                            <ProgressBar variant={progressColor(file)} animated={shouldAnimate(file)} now={file.progress} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
};

export default File;