import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Loading from 'Loading';
import Container from '../common/Container';
import PrivateRoute from 'PrivateRoute';
import FileUpload from '../FileUpload';

// Dashboard
const Dashboard = lazy(() => import('../Dashboard'));

// Group management
const Groups = lazy(() => import('../Groups/Groups'));
const Group = lazy(() => import('../Groups/Group'));

// Contacts management
const Contacts = lazy(() => import('../Contacts/Contacts'));

// File management
const Files = lazy(() => import('../Files/Files'));
const File = lazy(() => import('../Files/File'));

// Events management
const Events = lazy(() => import('../Events/Events'));
const EventsCreate = lazy(() => import('../Events/Create'));
const Event = lazy(() => import('../Events/Event'));

const View = lazy(() => import('../View'));

const AppRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <>
            <Container>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <PrivateRoute path={`${path}/dashboard`} exact component={Dashboard} />

                        <PrivateRoute path={`${path}/groups/:id`} component={Group} />
                        <PrivateRoute path={`${path}/groups`} exact component={Groups} />

                        <PrivateRoute path={`${path}/contacts`} exact component={Contacts} />

                        <PrivateRoute path={`${path}/files/:id`} component={File} />
                        <PrivateRoute path={`${path}/files`} exact component={Files} />

                        <PrivateRoute path={`${path}/events/create`} exact component={EventsCreate} />
                        <PrivateRoute path={`${path}/events/:id`} component={Event} />
                        <PrivateRoute path={`${path}/events`} exact component={Events} />

                        <Route path={`${path}/view/:token`} component={View} />
                        <Redirect to="/404" />
                    </Switch>
                </Suspense>
            </Container>
            <FileUpload />
        </>
    )
};

export default AppRoutes;
