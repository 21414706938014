import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_AUTHENTICATION_STATE } from './graphql/auth/queries';
import type { AuthenticationState } from 'auth/types';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
    const { data } = useQuery<AuthenticationState>(GET_AUTHENTICATION_STATE);

    if (!Component || !data) {
        // TODO: Consider redirecting to error page?
        return null;
    }

    return (
        <Route {...rest} render={(props) => (
            data.isAuthenticated === false
                ? <Redirect to={`/auth/login?redirect=${props.location.pathname}${props.location.search}`} />
                : <Component {...props} />
        )} />
    )
};

export default PrivateRoute
