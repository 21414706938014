import { fileUploadsVar } from 'graphql/FileUpload/variables';
import { ApolloClient, gql } from "@apollo/client";
import { GET_FILES } from 'graphql/Files/queries';
import axios from 'axios';
import update from 'immutability-helper';
import { CreateFileData, FileUploadState, FileUpload } from './types';
import FileType from 'file-type/browser';

const CREATE_FILE = gql`
mutation CreateFile($input: FileInput!) {
    createFile(input: $input) {
        id
        name
        data {
            fields
            url
        }
    }
}
`;

const uploadFile = async (file: FileUpload, index: number, client: ApolloClient<any>) => {
    let fileType = await FileType.fromBlob(file.file as Blob);

    fileUploadsVar(
        update(fileUploadsVar(), {
            [index]: {
                $merge: {
                    state: FileUploadState.PREPARING
                }
            }
        })
    );

    const { data } = await client.mutate<CreateFileData>({
        mutation: CREATE_FILE,
        variables: {
            input: {
                name: file.file.name,
                size: file.file.size,
                mime: fileType ? fileType.mime : 'application/unknown'
            }
        }
    });

    if (!data) {
        fileUploadsVar(
            update(fileUploadsVar(), {
                [index]: {
                    $merge: {
                        progress: 100,
                        state: FileUploadState.ERROR
                    }
                }
            })
        );

        return;
    }

    fileUploadsVar(
        update(fileUploadsVar(), {
            [index]: {
                $merge: {
                    state: FileUploadState.UPLOADING
                }
            }
        })
    );
    const form = new FormData();
    for (const field in data.createFile.data.fields) {
        form.append(field, data.createFile.data.fields[field]);
    }
    form.append('file', file.file);
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    axios.post(data.createFile.data.url, form, {
        headers: headers,
        onUploadProgress: (event) => {
            fileUploadsVar(
                update(fileUploadsVar(), {
                    [index]: {
                        $merge: {
                            progress: Math.round((event.loaded * 100) / event.total)
                        }
                    }
                }));
        }
    })
        .then((res) => {
            fileUploadsVar(
                update(fileUploadsVar(), {
                    [index]: {
                        $merge: {
                            progress: 100,
                            state: FileUploadState.COMPLETE
                        }
                    }
                })
            )
            const { files } = client.readQuery({
                query: GET_FILES
            });
            client.writeQuery({
                query: GET_FILES,
                data: {
                    files: [...files, {
                        id: data.createFile.id,
                        name: data.createFile.name
                    }]
                }
            });
        })
        .catch((err) => {
            fileUploadsVar(
                update(fileUploadsVar(), {
                    [index]: {
                        $merge: {
                            progress: 100,
                            state: FileUploadState.ERROR
                        }
                    }
                })
            );
        });
}

export const uploadFiles = (newFiles: File[], client: ApolloClient<any>) => {
    let fileUploads = fileUploadsVar();
    let baseIndex = fileUploads.length;

    fileUploadsVar(fileUploadsVar()?.concat(newFiles.map((file): FileUpload => {
        return {
            progress: 0,
            state: FileUploadState.READY,
            file: file
        };
    })));

    fileUploadsVar().slice(baseIndex).forEach((file, index) => {
        uploadFile(file, baseIndex + index, client);
    });
};

export const clearFiles = () => {
    fileUploadsVar([]);
}