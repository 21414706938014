import { initializeAuthState, registerAuthTypePolicies } from './auth';
import { initializeFileUploadState, registerFileUploadPolicies } from './FileUpload';
import { initializeLoadingState, registerLoadingTypePolicies } from './loading';
import type { TypePolicies } from '@apollo/client';

export const buildGraphQLTypePolicies = () => {
    let policy: TypePolicies = {};
    registerAuthTypePolicies(policy);
    registerLoadingTypePolicies(policy);
    registerFileUploadPolicies(policy);
    return policy;
}

export const initializeGraphQLState = () => {
    initializeAuthState();
    initializeLoadingState();
    initializeFileUploadState();
}
