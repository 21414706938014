import React from 'react';
import Sidebar from '../Sidebar';
import Content from '../Content';
import Header from '../Header';
import { Row, Col } from 'react-bootstrap';
import use100vh from 'utility/use100vh';

interface ContainerProps {
    children: React.ReactNode
}

const Container = (props: ContainerProps) => {
    const height = use100vh();

    return (
        <Row noGutters style={{ height: `${height}px` }}>
            <Col sm={2} className="d-none d-sm-block">
                <Sidebar />
            </Col>
            <Col xs={12} sm={10} >
                <Header />
                <Content>
                    {props.children}
                </Content>
            </Col>
        </Row>
    )
};

export default Container;