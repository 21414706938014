export enum FileUploadState {
    READY,
    PREPARING,
    UPLOADING,
    COMPLETE,
    ERROR
};

export interface FileUpload {
    state: FileUploadState,
    file: File,
    progress: number
};

export interface FileUploadsData {
    fileUploads: FileUpload[]
};

interface AWSFields {
    [x: string]: any
};

interface AWSData {
    url: string,
    fields: AWSFields
};

interface CreateFileResponse {
    id: string,
    name: string,
    data: AWSData
};

export interface CreateFileData {
    createFile: CreateFileResponse;
};