import { fileUploadsVar } from './variables';
import { addTypePolicy } from '../helpers';
import type { TypePolicies } from '@apollo/client';

export const registerFileUploadPolicies = (policy: TypePolicies) => {
    addTypePolicy(policy, {
        Query: {
            fields: {
                fileUploads: {
                    read() {
                        return fileUploadsVar();
                    }
                }
            }
        }
    });
}

export const initializeFileUploadState = () => {
    fileUploadsVar([]);
}