import React from 'react';
import { NProgress } from '@tanem/react-nprogress';
import { useLocation } from 'react-router-dom';
import Bar from './Bar';
import Container from './Container';

interface ProgressProps {
    loading: boolean
};

const Progress = (props: ProgressProps) => {
    const location = useLocation();

    return (
        <NProgress isAnimating={props.loading} key={location.key}>
            {({ isFinished, progress, animationDuration }) => (
                <Container
                    isFinished={isFinished}
                    animationDuration={animationDuration}
                >
                    <Bar
                        progress={progress}
                        animationDuration={animationDuration}
                    />
                    {/*
                    This example doesn't use a spinner component so the UI stays
                    tidy. You're free to render whatever is appropriate for your
                    use-case.
                    */}
                </Container>
            )}
        </NProgress>
    );
};

export default Progress;
