import React from 'react';
import { Card, Container } from 'react-bootstrap';

interface AuthContainerProps {
    children: React.ReactNode
};

const AuthContainer = (props: AuthContainerProps) => {
    return (
        <Container className="my-3">
            <Card body className="shadow-lg">
                <Container>
                    {props.children}
                </Container>
            </Card>
        </Container>
    )
};

export default AuthContainer;