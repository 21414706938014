import React from 'react';
import Link from 'common/Link';
import { Button, Card, Col, Container, Jumbotron, Row } from 'react-bootstrap';

const Home = () => {
    return (
        <main>
            <Jumbotron fluid>
                {/*
                  * Place container inside of Jumbotron to make the jumbotron
                  * full width, but the content aligned properly
                  */}
                <Container className="mt-4">
                    <h1>Hello!</h1>
                    <p>Welcome to Memory Inbox!</p>
                </Container>
            </Jumbotron>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1>Why use Memory Inbox?</h1>
                    </Col>
                    <Col xs={12}>
                        <p>Below are just some of the reasons!</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} md={6} lg={4} className="mb-2">
                        <Card>
                            <Card.Img variant="top" src="https://via.placeholder.com/200" />
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="mb-2">
                        <Card>
                            <Card.Img variant="top" src="https://via.placeholder.com/200" />
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="mb-2">
                        <Card>
                            <Card.Img variant="top" src="https://via.placeholder.com/200" />
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4 py-3">
                    <Col xs={12} className="text-center">
                        <h1>Get started today for free</h1>
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button as={Link} to="/auth/signup" variant="outline-primary">Sign Up</Button>
                    </Col>
                </Row>
            </Container>
        </main>
    )
};

export default Home;