import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PAGE_LOADING_STATE } from './graphql/loading/queries';
import Progress from './Loading/Progress';

// Home site
import PublicRoute from './public/Route';

// Auth pages
import AuthRoute from './auth/Route';

// App pages
import AppRoute from './app/Route';

import Loading from './Loading';

const Routes = () => {
    const { data } = useQuery(GET_PAGE_LOADING_STATE);

    return (
        <>
            <Progress loading={data ? data.isPageLoading : true} />
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route path="/app" component={AppRoute} />
                    <Route path="/auth" component={AuthRoute} />
                    <Route path="/" component={PublicRoute} />

                    {/* 404s handled by public routes */}
                    <Redirect to="/404" />
                </Switch>
            </Suspense>
        </>
    )
};

export default Routes;
