import React from 'react';

interface ContainerProps {
    children: React.ReactNode,
    isFinished: boolean,
    animationDuration: number
};

const Container = ({ children, isFinished, animationDuration }: ContainerProps) => (
    <div
        style={{
            opacity: isFinished ? 0 : 1,
            pointerEvents: 'none',
            transition: `opacity ${animationDuration}ms linear`,
        }}
    >
        {children}
    </div>
)

export default Container;
