import { useState, useEffect } from 'react';

const getRealHeight = () => {
    if (typeof window === 'undefined') return null;
    return document.documentElement?.clientHeight || window.innerHeight
}

const use100vh = () => {
    const [height, setHeight] = useState(getRealHeight);

    useEffect(() => {
        const setRealHeight = () => {
            const realHeight = getRealHeight()
            if (height !== realHeight) setHeight(realHeight);
        }
        window.addEventListener('resize', setRealHeight)
        return () => window.removeEventListener('resize', setRealHeight)
    }, [height]);

    return height;
};

export default use100vh;